<template>
  <div class="managingPeople hp100 bbox" v-loading="loading">
    <!-- 卡片视图 -->
    <el-card>
      <!-- 搜索-->
      <el-row :gutter="20" type="flex" justify="space-between">
        <el-col>
          <div
            style="margin-left:80%"
            class="flex align-center justify-end header-search-box">
            <el-input
              placeholder="请输入上报人名称"
              clearable
              v-model="queryInfo.condition.keyWord"
              class="header-search-input ml10">
              <el-button
                slot="append"
                icon="el-icon-search"
                class="textblue searchBtn"
                @click="search()"
              ></el-button>
            </el-input>
          </div>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"
        border
        fit
        :height="height"
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column label="编号" type="index" width="50">
        </el-table-column>
        <el-table-column label="上报人昵称" align="center" prop="memberName">
          <template slot-scope="scope">
            <span>{{ scope.row.memberName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="上报人头像" align="center" prop="memberLogo">
          <template slot-scope="scope">
            <img style="width:40%" :src="scope.row.memberLogo" />
          </template>
        </el-table-column>
        <el-table-column label="故障描述" align="center" prop="malfunctionInfo">
          <template slot-scope="scope">
            <span>{{ scope.row.malfunctionInfo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="故障类型" align="center" prop="repairsType">
          <template slot-scope="scope">
            <span>{{ scope.row.repairsType }}</span>
          </template>
        </el-table-column>
        <el-table-column label="反馈图片" align="center" prop="img">
          <template slot-scope="scope">
            <el-image
              style="width: 40%"
              :src="scope.row.img"
              :preview-src-list="scope.row.imgList"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="地址" align="center" prop="address">
          <template slot-scope="scope">
            <span>{{ scope.row.address }}</span>
          </template>
        </el-table-column>
        <el-table-column label="设备编号" align="center" prop="deviceId">
          <template slot-scope="scope">
            <span>{{ scope.row.deviceId }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="所属电子围栏"
          align="center"
          prop="electronicFenceName">
          <template slot-scope="scope">
            <span>{{ scope.row.electronicFenceName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="处理状态" align="center" prop="repairsStatus">
          <template slot-scope="scope">
            <span>{{scope.row.repairsStatus === 1 ? "未处理报修" : "已处理"}}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="repairsStatus">
          <template slot-scope="scope">
            <span v-if="scope.row.createTime">{{scope.row.createTime | dataFormat}}</span>
            <span v-else>---</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button type="text" @click="detail(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footerBox">
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.page"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </el-card>
    <el-dialog
      title="详情"
      :visible.sync="detailShow"
      width="600px">
      <div>
        <el-row :gutter="20">
          <el-form label-width="100px">
            <el-col :span="24">
              <el-form-item label="上报人昵称">
                <el-input v-model="detailInfo.creator" readonly style="width: 100%"/>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="故障描述">
                <el-input v-model="detailInfo.malfunctionInfo" readonly style="width: 100%"/>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="故障类型">
                <el-input v-model="detailInfo.repairsType" readonly style="width: 100%"/>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="反馈图片">
                <div class="picture">
                  <el-image
                    v-for="(item,index) in detailInfo.imgList" :key="index"
                    :src="item"
                    :preview-src-list="[item]"
                    style="width: 150px;height: 150px"/>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="地址">
                <el-input v-model="detailInfo.address" readonly style="width: 100%"/>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="设备编号">
                <el-input v-model="detailInfo.deviceId" readonly style="width: 100%"/>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="所属电子围栏">
                <el-input v-model="detailInfo.electronicFenceName" readonly style="width: 100%"/>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="处理状态">
                <el-input v-model="detailInfo.repairsStatus === 1 ? '未处理报修' : '已处理'" readonly style="width: 100%"/>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="detailShow = false">取 消</el-button>
          <el-button type="primary" @click="detailShow = false">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      height: window.innerHeight - 256, //表格高度
      value: "",
      tableData: [],
      loading: true,
      id: "",
      supplierId: "",
      ids: "",
      statu: "",
      searchInput: "",
      // 获取用户列表查询参数对象
      queryInfo: {
        // 当前页数
        currPage: 1,
        // 每页显示多少数据
        pageSize: 10,
        //查询条件
        condition: {
          id: "",
          supplierId: "",
          repairsStatus: 2,
          keyWord: ""
        }
      },
      total: 0,
      // 详情弹窗
      detailShow: false,
      detailInfo: {}
    };
  },
  created() {
    this.queryInfo.currPage = this.$route.query.currPage;
    this.queryInfo.pageSize = this.$route.query.pageSize;
    this.getListData();
    window.addEventListener("resize", this.getHeight);
  },
  mounted() {
    this.loading = false;
  },
  destroyed() {
    window.removeEventListener("resize", this.getHeight);
  },
  methods: {
    //条件查询
    search() {
      this.getListData();
    },

    // },
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getListData();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getListData();
    },

    detail (row) {
      this.detailShow = true
      this.detailInfo = row
    },

    getListData() {
      // 获取列表
      var that = this;
      that.statu = localStorage.getItem("employeeType");
      that.ids = localStorage.getItem("ids");
      // console.log(that.ids);
      // if (that.statu == 1) {
        that.$http
          .post("/repairsInfo/list", that.queryInfo)
          .then(function(response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.tableData = response.data.data.data;
              that.tableData.forEach((item, index) => {
                console.log('deviceImage',JSON.parse(item.deviceImage));
                item.imgList = JSON.parse(item.deviceImage);
                item.img = JSON.parse(item.deviceImage)[0];
              });
              console.log('imgList',that.tableData);
            }
          });
      // } else {
      //   that.$http
      //     .post("repairsInfo/list", that.queryInfo)
      //     .then(function(response) {
      //       if (response.data.code == 200) {
      //         that.total = response.data.data.count;
      //         that.tableData = response.data.data.data;
      //         that.tableData.forEach((item, index) => {
      //           that.tableData[index].imgList = item.deviceImage.split(",");
      //           that.tableData[index].img = item.deviceImage.split(",")[0];
      //         });
      //       }
      //     });
      // }
    },
    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - 256;
    }
  }
};
</script>

<style lang="less" scoped>
.textblue {
  color: #008eff;
}
.managingPeople {
  .el-card {
    height: calc(100vh - 114px);
    position: relative;
    .el-select > .el-input {
      width: 200px;
    }
    // .header-search-box .header-search-input {
    //     width: 325px;
    // }
    input::placeholder {
      font-size: 12px;
    }
    .handle-btn .el-button.add-column-btn {
      width: 100%;
      font-size: 13px;
      background-color: transparent;
      color: #008eff;
      z-index: 102;
    }
    .footerBox {
      position: absolute;
      width: 100%;
      height: 78px;
      left: 20px;
      right: 20px;
      bottom: 0;
    }
  }
}
</style>
